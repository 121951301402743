// timeUtils.js
export function timeAgo(timestamp) {
    const postedDate = new Date(timestamp);
    const now = new Date();

    const timeDifference = now - postedDate;
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30.44); // Approximate average month length
    const years = Math.floor(days / 365);

    if (years > 0) {
        return `${years} jaar geleden`;
    } else if (months > 0) {
        return `${months} ${months > 1 ? "maanden" : "maand"} geleden`;
    } else if (weeks > 0) {
        return `${weeks} ${weeks > 1 ? "weken" : "week"} geleden`;
    } else if (days > 0) {
        return `${days} ${days > 1 ? "dagen" : "dag"} geleden`;
    } else if (hours > 0) {
        return `${hours} uur geleden`;
    } else if (minutes > 0) {
        return `${minutes} ${minutes > 1 ? "minuten" : "minuut"} geleden`;
    } else {
        return `een paar seconden geleden`;
    }
}