import CrudEntity from "@/shared/CrudEntity.js";

class OnderdeelEntity extends CrudEntity {
    static _uri = "api/v1/onderdelen";
    _name       = "onderdeel";

    beforeSave(data) {
        let copy = Object.assign({}, data);

        if (typeof copy.data === "object") {
            copy.data = JSON.stringify(copy.data);
        }

        return copy;
    }
}

export default OnderdeelEntity;
