<template>
    <div class="alert alert-danger" role="alert">
        Er is iets fout gegaan. Probeer het later opnieuw.<br />
        <small>{{ vRecursiveComponent }}</small>
    </div>
</template>

<script>
    import { onMounted } from "vue";

    export default {
        name:  "ComponentErrorComponent",
        props: {
            vRecursiveComponent: {
                type:    String,
                default: "",
            },
        },
        setup() {
            onMounted(() => {
                throw new Error("Error in component");
            });
            return {};
        },
    };
</script>

<style lang="scss" scoped>
    small {
        font-size: 6px;
    }
</style>
