<template>
    <div v-if="authStore.getUser" class="bg-white">
        <div class="profile-pic-card">
            <div class="options-container">
                <div class="image-container">
                    <div class="content">
                        <img alt="" src="" />
                    </div>
                </div>
                <!-- <button><i class="bi bi-pencil"></i></button> -->
            </div>
            <div class="name h4">
                {{ authStore.getUser.first_name }} {{ authStore.getUser.name }}
            </div>
            <div class="role">
                {{ authStore.getUserRole.name }}
            </div>
        </div>
    </div>
</template>

<script>
    import { useAuthStore } from "@/shared/Stores/AuthStore";

    export default {
        name: "ProfilePicCard",

        setup() {
            const authStore = useAuthStore();

            return {
                authStore,
            };
        },
    };
</script>

<style lang="scss" scoped>
    .bg-white {
        .profile-pic-card {
            padding: 40px 60px;
            display: flex;
            justify-content: center;
            flex-direction: column;

            .name, .role {
                text-align: center;
                text-transform: capitalize;
            }
        }

        .options-container {
            position: relative;
            display: flex;
            justify-content: center;

            button {
                position: absolute;
                left: calc(50% + 50px);
                border: 3px solid white;
                bottom: 40px;
                background: var(--bs-info);
                border-radius: 100%;
                width: 45px;
                height: 45px;
                font-size: 20px;
                transition: all .2s ease-out;

                &:hover {
                    transform: scale(1.1);
                    transition: all .2s ease-in;
                }
            }
        }

        .image-container {
            position: relative;
            width: 100%;
            background-color: var(--bs-info);
            max-width: 250px;
            margin-bottom: 40px;
            overflow: hidden;
            border-radius: 100%;

            &::after {
                content: "";
                display: block;
                padding-bottom: 100%;
            }

            .content {
                padding-top: 10%;
                text-align: center;
                position: absolute;
                width: 100%;
                height: 100%;
                background-image: url("@/assets/images/mascot1.svg");
                background-position: center -10%;
                background-repeat: no-repeat;
            }
        }
    }

    @media (width >= 992px) and (width <= 1200px) {
        .bg-white {
            .profile-pic-card {
                padding: 20px 30px;
            }
        }
    }
</style> 
