<template>
    <div v-if="needsToLoad && !loading && lesprogrammaStore.getLesprogrammas.length > 0" class="bg-white w-100 p-4">
        <p v-if="userRole == 'demo'" class="title">
            Kies een lesprogramma
        </p>
        <p v-if="userRole == 'leerling'" class="title">
            Lesprogramma
        </p>
        <div class="select-container">
            <select
                v-if="lesprogrammaStore.getLesprogrammas.length > 0 && !loading && userRole == 'demo'"
                id="validationServer04"
                class="form-select custom-select"
                required
                @change="handleSelectChange($event)"
            >
                <template
                    v-if="lesprogrammaStore.getLesprogrammas.length > 0 && !loading"
                >
                    <option
                        v-for="lesprogramma in lesprogrammaStore.getLesprogrammas"
                        :key="lesprogramma.uuid"
                        :selected="lesprogramma.uuid == lesprogrammaStore.getChosenLesprogrammaUUID"
                        :value="lesprogramma.uuid"
                    >
                        {{ lesprogramma.title }}
                    </option>
                </template>
                <template v-else>
                    <option value="">
                        Laden...
                    </option>
                </template>
            </select>
            <p v-if="lesprogrammaStore.getLesprogrammas.length == 1 && userRole == 'leerling'">
                {{ lesprogrammaStore.getLesprogrammas[0].title }}
            </p>
        </div>
    </div>
    <div v-if="needsToLoad && loading" class="bg-white w-100 p-4 text-center">
        <div class="spinner-border text-primary" role="status"></div>
    </div>
    <div v-if="needsToLoad && !loading && lesprogrammaStore.getLesprogrammas.length === 0">
        <smarty-router-link class="btn btn-primary" :to="{name: 'profiel', hash: '#Licenties'}">
            Licentie toevoegen <i class="bi bi-key-fill fs-5"></i>
        </smarty-router-link>
    </div>
</template>

<script>
    import {
        computed,
        onMounted,
        ref
    } from "vue";

    import { useAuthStore }         from "@/shared/Stores/AuthStore.js";
    import { useLesprogrammaStore } from "@/stores/modules/lesprogrammaStore.js";

    export default {
        name: "SelectProgram",
        setup() {
            const lesprogrammaStore = useLesprogrammaStore();
            const authStore         = useAuthStore();
            const userRole          = ref(authStore.getUserRole.level);
            const needsToLoad       = computed(() => {
                if (!authStore.getUser) {
                    return false;
                }
                if (![
                    "leerling",
                    "demo",
                ].includes(userRole.value)) {
                    return false;
                }
                return true;
            });
            const loading           = computed(() => !lesprogrammaStore.getLesprogrammaLoaded && lesprogrammaStore.getLesprogrammaLoading && needsToLoad.value);


            onMounted(() => {
                if (!needsToLoad.value) {
                    return;
                }
                if (!loading.value) {
                    lesprogrammaStore.fetchLesprogrammas().then(() => {});
                }
            });

            const handleSelectChange = (event) => {
                lesprogrammaStore.setChosenLesprogrammaUUID(event.target.value);
            };

            return {
                handleSelectChange,
                lesprogrammaStore,
                loading,
                needsToLoad,
                userRole,
            };
        },
    };
</script>

<style lang="scss" scoped>
    .title {
        color: #1B1327;
        font-family: Gotham, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px;
    }

    .select-container {
        margin-bottom: 18px;
        position: relative;
    }

    .custom-select {
        padding: 20px 15px;
        color: #1B1327;
        font-family: Gotham, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
    }

    .dropdown-icon {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translate(0, -50%);
    }

    a {
        color: #2E247A;
        font-family: Gotham, sans-serif;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 184.615% */
        text-decoration-line: underline;
    }
</style>
