import moment from "moment/moment.js";

import CrudEntity            from "@/shared/CrudEntity.js";
import { datumTextFromDate } from "@/shared/utils.js";

class AgendaAfspraakEntity extends CrudEntity {
    static _uri = "api/v1/agenda/afspraken";
    _name       = "agenda_afspraak";

    transformDatum(date) {
        this.datum_tekst = datumTextFromDate(date);
        this.datum       = moment(date).format("YYYY-MM-DD");
    }
}

export default AgendaAfspraakEntity;
