<template>
    <footer class="container mt-4">
        <div class="row">
            <div class="col-12 version col-md-4">
                {{ packName }} versie {{ versionString }}
            </div>
            
            <div class="col-12 col-md-4">
                <a href="https://www.explain.nl/wp-content/uploads/2023/11/Algemene-Voorwaarden-eXplain-definitief-d.d.-26-09-2023.pdf" target="_blank">Algemene voorwaarden</a>
                -
                <a href="https://www.explain.nl/wp-content/uploads/2023/09/NL-2302-Privacystatement-eXplain-verwerkingsverantwoordelijke.docx-PDF-1.pdf" target="_blank">Privacy statement</a>
            </div>

            <div class="col-12 col-md-4">
                <p>Beschikbaar gesteld door <img :src="imgUrl" alt="logo" /></p>
            </div>
        </div>
    </footer>
</template>

<script>
    import { ref } from "vue";

    import imgUrl      from "@/assets/images/logo_explain.png";
    import { ucfirst } from "@/shared/utils.js";


    export default {
        name: "AppFooter",
        setup() {
            const imgUrlRef = ref(imgUrl);
        
            return {
                imgUrl:        imgUrlRef,
                versionString: `${__VERSION_NUMBER__}@${__COMMIT_HASH__} ${__COMPILE_DATE__}`,
                packName:      ucfirst(`${__PACKAGE_NAME__}`),
            };
        },
    };
</script>

<style lang="scss" scoped>
    footer {
        text-align: right;
        font-size: 12px;
        color: var(--bs-primary);
        line-height: 24px;
    }

    .version {
        text-align: left;
        font-size: 10px;
    }
</style>
