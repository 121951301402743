export default {
    mounted() {
        if (this.$el && (this.$options.name || this.$options.__file)) {
            if (!this.$el.setAttribute) {
                return;
            }
            this.$el.setAttribute("v-component", this.$options.name || this.$options.__file.split("/").pop().replace(".vue", ""));
        }
    },
};
