import CrudEntity from "@/shared/CrudEntity.js";

class LesprogrammaEntity extends CrudEntity {
    static _uri = "api/v1/lesprogrammas";
    _name       = "lesprogramma";


    beforeSave(data) {
        let copy = Object.assign({}, data);

        if (typeof copy.data === "object") {
            copy.data = JSON.stringify(copy.data);
        }

        return copy;
    }
}

export default LesprogrammaEntity;
