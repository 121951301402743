import axios, { CanceledError } from "axios";

import AbortService     from "@/shared/Services/AbortService.js";
import { useAuthStore } from "@/shared/Stores/AuthStore.js";

class ApiService {
    static signals = {};

    static getAbortController(requestObject = {}) {
        let abortController = new AbortService((c) => {
            delete ApiService.signals[c.id];
        });
        abortController.setRequest(requestObject);
        ApiService.signals[abortController.id] = abortController;
        return abortController;
    }

    static cancelAllSignals() {
        for (let key in ApiService.signals) {
            ApiService.signals[key].abort();
        }
    }

    static getHeaders(headers = {}) {
        const authStore = useAuthStore();
        let h           = {
            "Content-Type": "application/json",
            "Accept":       "application/json",
            "X-Client-ID":  import.meta.env.VITE_APP_CLIENT_ID,
        };

        for (let key in headers) {
            if (headers[key] === null || headers[key] === undefined) {
                delete h[key];
                continue;
            }
            h[key] = headers[key];
        }

        if (authStore.getAccessToken) {
            const accessToken  = authStore.getAccessToken;
            h["Authorization"] = `Bearer ${accessToken}`;
        }
        return h;
    }

    /**
     * Send a request to the API
     * @param {"get"|"put"|"post"|"delete"} method  The method to use
     * @param {string} uri API endpoint, i.e. `users/me`
     * @param {Object} params Query parameters to put in URL
     * @param {Object} data Data to send in the request body
     * @param {Object} headers Headers to send with the request, using null or undefined will remove existing header
     * @param {Object} extraOptions Extra options to send with the request
     * @param {boolean} extraOptions.cancel  false will not add the signal to the request
     * @returns {Promise<unknown>} The response from the API
     * @private
     */
    static async _sendRequest(method, uri, params = {}, data = {}, headers = {}, extraOptions = {}) {
        const authStore = useAuthStore();

        if ([
            "get",
            "put",
            "post",
            "delete",
        ].indexOf(method.toLowerCase()) === -1) {
            throw new Error("Method not supported");
        }
        if (!uri) {
            throw new Error("URI is required");
        }

        let url = this.getUrl(uri, params);

        let requestObject     = {};
        requestObject.method  = method;
        requestObject.headers = this.getHeaders(headers);


        if (data && Object.keys(data).length > 0) {
            requestObject.data = JSON.stringify(data);
        }
        requestObject.url   = url;
        let abortController = ApiService.getAbortController(requestObject);
        if (!(extraOptions.cancel === false)) {
            requestObject.signal = abortController.signal;
        }
        return new Promise((resolve, reject) => {
            axios(requestObject).then((response) => {
                abortController.cleanup();
                if (response.status === 200) {
                    resolve({
                        data:     response.data,
                        response: response,
                        headers:  response.headers,
                    });
                } else if (response.status === 204) {
                    resolve({
                        data:     [],
                        response: response,
                        headers:  response.headers,
                    });
                } else {
                    reject(response);
                }
            }).catch((error) => {
                if (error instanceof CanceledError) {
                    return;
                }
                abortController.cleanup();
                if (error.response && error.response.data) {
                    if (error.response.status === 401) {
                        /* eslint-disable-next-line no-console */
                        console.error("Error in ApiService, we gaan uitloggen", error);
                        authStore.clearTokens();
                        window.location.href = "/login";
                    } else if (error.response.status === 404 || error.response.status === 204) {
                        resolve({
                            data:     null,
                            response: error.response,
                            headers:  error.response.headers,
                        });
                    }
                    reject(error);
                } else {
                    reject(error);
                }
            });
        });
    }

    static getUrl(uri, queryParams = {}) {
        if (!uri) {
            throw new Error("URI is required");
        }
        let url = `${import.meta.env.VITE_APP_API_URL}/${uri}`.replace(/([^:]\/)\/+/g, "$1");
        if (queryParams && Object.keys(queryParams).length > 0) {
            let params = {};
            for (let key in queryParams) {
                let newKey = key;
                if (key.indexOf(".") !== -1) {
                    newKey = key.replaceAll(".", "___");
                }
                params[newKey] = queryParams[key];
            }
            url += "?" + new URLSearchParams(params).toString();
        }
        return url;
    }

    static get(uri, params = {}, headers = {}, extraOptions = {}) {
        return this._sendRequest("get", uri, params, {}, headers, extraOptions);
    }

    static post(uri, params = {}, data = {}, headers = {}, extraOptions = {}) {
        return this._sendRequest("post", uri, params, data, headers, extraOptions);
    }

    static put(uri, params = {}, data = {}, headers = {}, extraOptions = {}) {
        if (params.uuid === "NULL") {
            delete params.uuid;
        }
        if (params.uuid) {
            uri = `${uri}/${params.uuid}`;
            delete params.uuid;
        }
        return this._sendRequest("put", uri, params, data, headers, extraOptions);
    }

    static delete(uri, params = {}, data = {}, headers = {}, extraOptions = {}) {
        if (params.uuid === "NULL") {
            delete params.uuid;
        }
        if (params.uuid) {
            uri = `${uri}/${params.uuid}`;
            delete params.uuid;
        }
        return this._sendRequest("delete", uri, params, data, headers, extraOptions);
    }

    static download(uri, params, data, extraOptions = {}) {
        return this._sendRequest("get", uri, params, data, {
            "Content-Type": null,
            "Accept":       null,
        }, extraOptions);
    }
}

export default ApiService;
