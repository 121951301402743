<template>
    <header class="container custom-container">
        <div class="row justify-content-between">
            <div class="col d-block">
                <h2 rel="app_name">
                    <smarty-router-link :to="{name: 'dashboard'}">
                        {{ $APP_NAME }}
                    </smarty-router-link>
                </h2>
            </div>
            <div v-if="user" class="col text-right">
                <div class="user-profile icons">
                    <smarty-router-link
                        :to="{name: 'dashboard'}"
                        class="user-info"
                    >
                        <div class="profile-pic">
                            <i class="bi bi-person"></i>
                        </div>
                        <div class="profile-data">
                            <span class="username">{{ user.first_name }} {{ user.name }}</span>
                            <span class="role">{{ user.role.name }}</span>
                        </div>
                    </smarty-router-link>
                    <smarty-router-link
                        :to="{name: 'inbox'}"
                        class="notifications"
                    >
                        <i class="bi bi-envelope"></i>
                        <span v-if="counter > 0 && loaded" class="counter">{{ counter }}</span>
                        <template v-if="!loaded">
                            <span class="spinner-border spinner-border-sm spinner-counter text-primary"></span>
                        </template>
                    </smarty-router-link>
                    <smarty-router-link
                        :to="{name: 'dashboard'}"
                        class="notifications"
                    >
                        <i class="bi bi-house"></i>
                    </smarty-router-link>
                </div>
            </div>
            <div v-else class="col text-right">
                <smarty-router-link :to="{ name: 'faq' }" class="btn btn-success header-link">
                    FAQ
                </smarty-router-link>
                <smarty-router-link :to="{ name: 'login' }" class="btn btn-primary header-link">
                    Inloggen
                </smarty-router-link>
                <smarty-router-link :to="{ name: 'register' }" class="btn btn-primary header-link">
                    Registreer
                </smarty-router-link>
            </div>
        </div>
    </header>
</template>

<script>
    import {
        onMounted,
        ref
    }                   from "vue";
    import { useRoute } from "vue-router";

    import SmartyRouterLink     from "@/components/global/SmartyRouterLink.vue";
    import AgendaAfspraakEntity from "@/shared/Entities/AgendaAfspraakEntity.js";
    import BeoordelingEntity    from "@/shared/Entities/BeoordelingEntity.js";
    import ApiService           from "@/shared/Services/ApiService.js";
    import { useAuthStore }     from "@/shared/Stores/AuthStore";

    export default {
        name:       "AppHeader",
        components: {
            SmartyRouterLink,
        },
        setup() {
            const authStore         = useAuthStore();
            const route             = useRoute();
            const isLogin           = (route.fullPath.indexOf("login") > 0) ? 1 : 0;
            const user              = ref(null);
            const showBeoordelingen = ref(null);
            const counter           = ref(0);
            const loaded            = ref(false);

            const isCurrentRoute = (routePath) => {
                return route.path === routePath;
            };

            const getBeoordelingen = () => {
                if(authStore.user.role.level == "manager"){
                    return;
                }
                if (showBeoordelingen.value) {
                    BeoordelingEntity.find({
                        "*antwoord.status": "(=)verzonden",
                        inbox:              true,
                    }, [
                        "antwoord",
                        "antwoord.opdracht",
                        "antwoord.user",
                    ], 0, -1).then(({ data }) => {
                        counter.value += data.length;
                    });
                }
            };

            const getUitnodingingen = () => {
                ApiService.get("/api/v1/users/me/afspraken", {
                    limit: -1,
                    inbox: true,
                }).then(({ data }) => {
                    data = AgendaAfspraakEntity._resolveResponseData(data, true);
                    counter.value += data.length;
                });
            };

            const getAllMessages = async () => {
                getBeoordelingen();
                getUitnodingingen();
            };

            onMounted(() => {
                if (authStore.getUser) {
                    user.value              = authStore.getUser;
                    showBeoordelingen.value = user.value.role.level != "leerling";

                    getAllMessages()
                        .then(loaded.value = true)
                        .catch(loaded.value = true);
                }
            });

            return {
                route,
                isLogin,
                isCurrentRoute,
                user,
                counter,
                showBeoordelingen,
                loaded,
            };
        },
    };
</script>

<style lang="scss" scoped>
    header {
        padding-top: 25px;
        padding-bottom: 25px;

        .row {
            .col {
                &:last-child {
                    text-align: right;
                }

                &.text-right {
                    text-align: right;

                    > span {
                        margin-left: 15px;
                    }
                }

                .icons {
                    > span {
                        margin-left: 15px;
                    }
                }
            }
        }

        .header-link {
            + .header-link {
                margin-left: 15px;
            }
        }

        h2 {
            &[rel="app_name"] {
                a {
                    text-decoration: none;
                }
            }
        }
    }
</style>
