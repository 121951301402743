import {
    createRouter,
    createWebHistory
} from "vue-router";

//import ApiService       from "@/shared/Services/ApiService.js";
import ApiService       from "@/shared/Services/ApiService.js";
import { useAuthStore } from "@/shared/Stores/AuthStore";


export const dynamicRoute =
                 {
                     path:      "/:pathMatch(.*)*",
                     name:      "dynamic",
                     component: () => import("@/views/pages/NotFoundPage.vue"),
                     meta:      { requiresAuth: true },
                 };


const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes:  [
        {
            path:      "/callback",
            name:      "callback",
            component: () => import("@/views/pages/CallbackPage.vue"),
            meta:      {
                requiresAuth: false,
                disallowAuth: true,
            },
        },
        {
            path:     "/",
            name:     "root",
            redirect: "/login",
            meta:     {
                requiresAuth: false,
                disallowAuth: true,
            },
        },
        {
            path:      "/login",
            name:      "login",
            component: () => import("@/views/pages/LoginPage.vue"),
            meta:      {
                requiresAuth: false,
                disallowAuth: true,
            },
        },
        {
            path:      "/logout",
            name:      "logout",
            component: () => import("@/views/pages/LogoutPage.vue"),
            meta:      { requiresAuth: true },
        },
        {
            path:      "/registreren",
            name:      "register",
            component: () => import("@/views/pages/Register.vue"),
            meta:      {
                requiresAuth: false,
                disallowAuth: true,
            },
        },
        {
            path:      "/planner",
            name:      "planner",
            component: () => import("@/views/pages/Planner.vue"),
            meta:      { requiresAuth: true },
        },
        {
            path:      "/bedrijf",
            name:      "bedrijf",
            component: () => import("@/views/pages/Bedrijf.vue"),
            meta:      { requiresAuth: true },
        },
        {
            path:      "/inbox",
            name:      "inbox",
            component: () => import("@/views/pages/InboxPage.vue"),
            meta:      { requiresAuth: true },
        },
        {
            path:      "/profiel",
            name:      "profiel",
            component: () => import("@/views/pages/ProfilePage.vue"),
            meta:      { requiresAuth: true },
        },
        {
            path:      "/rapport",
            name:      "rapport",
            component: () => import("@/views/pages/Report.vue"),
            meta:      { requiresAuth: true },
        },
        {
            path:      "/dashboard",
            name:      "dashboard",
            component: () => import("@/views/DynamicPage.vue"),
            meta:      { requiresAuth: true },
        },
        // All other pages require auth
        dynamicRoute,
    ],
});

router.beforeEach((to, from, next) => {
    const authStore = useAuthStore();
    // TODO: Deze weer terug, dit is om te testen of dit de oorzaak is van STYB-174: [Leer] Pagina Profiel wijzigen blijft in loop hangen
    if (from.query !== to.query || from.path !== to.path || from.name !== to.name) {
        ApiService.cancelAllSignals();
    }
    if (to.meta.requiresAuth && !authStore.isLoggedIn) {
        next({
            name:  "login",
            query: { redirect: to.fullPath },
        });
        return;
    } else if (to.meta.disallowAuth && authStore.isLoggedIn) {
        next({ name: "dashboard" });
        return;
    } else {
        if ([
            "login",
            "register",
            "callback",
        ].indexOf(to.name) !== -1) {
            next();
            return;
        }
        if (to.meta.roles && to.meta.roles.length) {
            const authStore = useAuthStore();
            const role      = authStore.getUserRole.uuid;
            if (!role || !to.meta.roles.includes(role)) {
                if (to.name !== "login") {
                    if (authStore.isLoggedIn) {
                        next({
                            name: "dashboard",
                        });
                        return;
                    } else {
                        next({
                            name:  "login",
                            query: { redirect: to.fullPath },
                        });
                        return;
                    }
                }
            }
        } else {
            // Geen rollen gedefinieerd, dus we moeten ingelogd zijn
            if (!authStore.isLoggedIn) {
                if (to.name !== "login") {
                    next({
                        name:  "login",
                        query: { redirect: to.fullPath },
                    });
                    return;
                }
            }
        }
    }
    next();
});


export default router;
