import CrudEntity            from "@/shared/CrudEntity.js";
import { jsonParseIfNeeded } from "@/shared/utils.js";

class OpdrachtEntity extends CrudEntity {
    static _uri = "api/v1/opdrachten";
    _name       = "opdracht";

    static transformData(data) {
        data = jsonParseIfNeeded(data, {});
        return data || {};
    }

    beforeSave(data) {
        let copy = Object.assign({}, data);

        if (typeof copy.data === "object") {
            copy.data = JSON.stringify(copy.data);
        }

        return copy;
    }
}

export default OpdrachtEntity;
