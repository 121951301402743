<template>
    <template v-for="(item, index) in blocks" :key="item.uuid">
        <component
            :is="loadEditorContentBlock(readContentBlockField(item, 'type'))"
            :data="item"
            :index="index"
            @update-value="updateValue"
        >
            <template v-if="item.children && item.children.length > 0" #default>
                <recursive-content :items="item.children"></recursive-content>
            </template>
        </component>
    </template>
</template>

<script>
    import {
        computed,
        defineAsyncComponent
    }                    from "vue";
    import VueJsonPretty from "vue-json-pretty";

    import {
        loadEditorContentBlock,
        readContentBlockField,
        snakeToPascal
    } from "@/shared/contentBlockUtils.js";


    export default {
        name:         "RecursiveContent",
        components:   {
            RecursiveContent: defineAsyncComponent(() => import("@/views/RecursiveContent.vue")),
            VueJsonPretty,
        },
        inheritAttrs: false,

        props: {
            items: {
                type:     Array,
                required: true,
            },
        },

        emits: [ "update-value" ],

        setup(props, { emit }) {
            const blocks = computed(() => {
                return props.items.map((item) => {
                    if (item.kind === "blokgroep") {
                        item.children = [];
                    }
                    if (item.fields && Array.isArray(item.fields)) {
                        let fields = {};
                        for (let i = 0; i < item.fields.length; i++) {
                            let name     = item.fields[i].key;
                            fields[name] = item.fields[i];
                        }
                        item.fields = fields;
                    }
                    return item;
                });
            });

            const updateValue = (val) => {
                emit("update-value", val);
            };

            return {
                loadEditorContentBlock,
                readContentBlockField,
                blocks,
                snakeToPascal,
                updateValue,
            };
        },
    };
</script>
