import { clone } from "lodash";

import router, { dynamicRoute } from "@/router/index.js";
import {
    forceRouteSlash,
    getSlug
}                               from "@/shared/dynamicRoutesUtils.js";
import PageEntity               from "@/shared/Entities/PageEntity.js";
import {
    jsonParseIfNeeded,
    showError
}                               from "@/shared/utils.js";
import DynamicPage              from "@/views/DynamicPage.vue";

export function initializeApp() {
    return new Promise((resolve, reject) => {
        let routerRoutes = router.getRoutes();
        PageEntity.find({}, [ "roles" ], 0, 9999).then(({ data }) => {
            let routes = data;
            if (!routes) {
                resolve(router);
                return;
            }
            router.removeRoute("dynamic");
            routes.forEach((route) => {
                const routeUrl = forceRouteSlash(route);
                let routeName  = route.slug || null;
                if (routeName === null) {
                    routeName = getSlug(routeUrl);
                }

                // If route already exists, merge roles and set requiresAuth to true
                if (router.hasRoute(routeName)) {
                    let existingRoute = clone(routerRoutes.find(r => r.name === routeName));
                    router.removeRoute(routeName);
                    if (route.roles) {
                        if (!existingRoute.meta) {
                            existingRoute.meta = {};
                        }
                        existingRoute.meta.roles = [
                            ...(existingRoute?.meta?.roles ?? []),
                            ...route.roles,
                        ];
                    }
                    existingRoute.meta.requiresAuth = true;
                    existingRoute.meta.updated      = true;
                    if (!existingRoute.component) {
                        existingRoute.component = () => DynamicPage;
                    }
                    if (!existingRoute.props) {
                        existingRoute.props = {};
                    }
                    existingRoute.props.title = existingRoute.props.title || (route.title || "");
                    existingRoute.props.items = existingRoute.props.items || (jsonParseIfNeeded(route.data)?.items || []);
                    router.addRoute(existingRoute);
                } else {
                    router.addRoute({
                        name:      routeName,
                        path:      routeUrl,
                        component: DynamicPage,
                        meta:      {
                            //requiresAuth: true,
                            roles: route.roles ?? [],
                            added: true,
                        },
                        props:     {
                            title: (route.title || ""),
                            items: (jsonParseIfNeeded(route.data)?.items || []),
                        },
                    });
                }
            });
            router.addRoute(dynamicRoute);
            resolve(router);
        }).catch((error) => {
            showError(error);
            reject(error);
        });
    });
}