import { v4 as uuidv4 } from "uuid";

class AbortService extends AbortController {
    id            = "";
    timeout       = null;
    requestObject = {};

    constructor(callbackFn = () => {}) {
        super();
        this.id         = uuidv4();
        this.timeout    = setTimeout(() => this.abort(), 30000);
        this.callbackFn = callbackFn;
    }

    setRequest(requestObject = {}) {
        this.requestObject = requestObject;
    }

    callbackFn = () => {};

    beforeAbort() {
        clearTimeout(this.timeout);
    }

    abort(reason) {
        this.beforeAbort();
        super.abort(reason);
        this.callbackFn(this, reason);
    }

    cleanup() {
        this.beforeAbort();
        this.callbackFn(this);
    }
}

export default AbortService;
