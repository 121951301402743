<template>
    <component
        :is="listitem ? 'li': 'span'"
        :class="{ active: active, 'd-none': noAccess }"
        :data-slug="slug"
    >
        <router-link
            v-if="exists && !disabled"
            :class="[{ active: active }, $props.class ]"
            :to="checkedTo"
        >
            <div v-if="icon && !$slots.default" class="d-flex">
                <i :class="icon"></i>
                <span>{{ title }}<slot></slot></span>
            </div>
            <span v-if="!icon">{{ title }}<slot></slot></span>
        </router-link>
        <a
            v-else-if="disabled"
            :class="[{ active: active }, $props.class, 'router-link', 'disabled' ]"
            :data-to="JSON.stringify(checkedTo)"
        >
            <div v-if="icon && title" class="d-flex">
                <i :class="icon" class="icon"></i>
                <span>{{ title }}<slot></slot></span>
            </div>
            <span v-if="!icon && title && !$slots.default">
                {{ title }}
                <slot></slot>
            </span>
        </a>
        <a
            v-else
            :class="[{ active: active }, $props.class, 'router-link', 'not-found' ]"
            :data-to="JSON.stringify(checkedTo)"
        >
            <div v-if="icon && title" class="d-flex">
                <i :class="icon" class="icon"></i>
                <span>{{ title }}<slot></slot></span>
            </div>
            <span v-if="!icon && title">{{ title }}<slot></slot></span>
        </a>
    </component>
</template>

<script>
    import {
        computed,
        onMounted,
        ref
    } from "vue";
    import {
        useRoute,
        useRouter
    } from "vue-router";

    import { useAuthStore }         from "@/shared/Stores/AuthStore.js";
    import { getNewBreadcrumbData } from "@/shared/utils.js";

    export default {
        name:         "SmartyRouterLink",
        inheritAttrs: false,
        props:        {
            title:    {
                type:    String,
                default: null,
            },
            icon:     {
                type:     String,
                required: false,
                default:  null,
            },
            to:       {
                type:     [
                    Object,
                    String,
                ],
                required: true,
            },
            slug:     {
                type:    String,
                default: null,
            },
            listitem: {
                type:     Boolean,
                required: false,
                default:  false,
            },
            class:    {
                type:     String,
                required: false,
                default:  "",
            },
            disabled: {
                type:     Boolean,
                required: false,
                default:  false,
            },
        },
        setup(props) {
            const route     = useRoute();
            const router    = useRouter();
            const authStore = useAuthStore();

            const hash    = ref(route.hash ?? "");
            const newHash = (to) => {
                let newData = getNewBreadcrumbData(hash.value, route, to);
                if (to.hash) {
                    return to.hash;
                }
                if (!newData) {
                    return "";
                }
                return `#BC_${btoa(JSON.stringify(newData))}`;
            };

            const findRoute = (to) => {
                if (typeof to === "string") {
                    // Check if router has route with name or url from to
                    let nRoute = null;
                    if (router.hasRoute(to)) {
                        nRoute = router.getRoutes().find(route => route.name === to);
                        return nRoute;
                    }
                    let pRoute = router.getRoutes().find(route => route.path === to);
                    if (pRoute) {
                        return pRoute;
                    }
                }
                // Als het geen string is moet het een object zijn
                if (to.name) {
                    let nRoute = router.getRoutes().find(route => route.name === to.name);
                    if (nRoute) {
                        return nRoute;
                    }
                }
                if (to.path) {
                    let pRoute = router.getRoutes().find(route => route.path === to.path);
                    if (pRoute) {
                        return pRoute;
                    }
                }
                return null;
            };

            const getRoute = (___to) => {
                let _to = findRoute(___to);
                if (!_to) {
                    return null;
                }
                let to = {
                    name:           _to.name,
                    path:           _to.path,
                    params:         _to.params ?? {},
                    query:          _to.query ?? {},
                    hash:           _to.hash ?? "",
                    meta:           _to.meta ?? {},
                    fullPath:       _to.fullPath,
                    matched:        _to.matched,
                    redirectedFrom: _to.redirectedFrom,
                    redirectedTo:   _to.redirectedTo,
                    metaInfo:       _to.metaInfo,
                };
                if (typeof props.to === "object") {
                    if (props.to.params) {
                        to.params = props.to.params;
                    }
                    if (props.to.query) {
                        to.query = props.to.query;
                    }
                    if (props.to.hash) {
                        to.hash = props.to.hash;
                    }
                }
                return to;
            };

            const checkedTo = computed(() => {
                let to = getRoute(props.to);
                if (to.path.indexOf(":") !== -1) {
                    // We need a parameter, which one?
                    let requiredParams = to.path.match(/:(\w+)/g);
                    if (requiredParams) {
                        requiredParams.forEach((param) => {
                            let paramName = param.replace(":", "");
                            if (!to.params[paramName]) {
                                /* eslint-disable-next-line no-console */
                                console.error(`Parameter ${paramName} is required for route ${to.name}`);
                                return false;
                            }
                        });
                    }
                }
                if (to) {
                    let hash = newHash(to);
                    return {
                        name:   to.name,
                        path:   to.path,
                        params: to.params ?? {},
                        query:  to.query ?? {},
                        hash:   hash,
                        meta:   to.meta ?? {},
                    };
                }
                return false;
            });

            const routeName = computed(() => {
                return checkedTo.value.name || props.slug;
            });

            const exists = ref(false);
            const active = ref(false);

            const noAccess = computed(() => {
                let to = checkedTo.value;
                if (to?.meta?.requiresAuth === false || !to?.meta?.roles) {
                    return false;
                }
                if (to.meta.roles.length === 0) {
                    return false;
                }
                let role = authStore.getUserRole.uuid;
                if (!role) {
                    return true;
                }

                return !to.meta.roles.includes(role);
            });

            onMounted(() => {
                exists.value = !!checkedTo.value;

                if (!exists.value) {
                    /* eslint-disable-next-line no-console */
                    console.warn(`Route ${routeName.value} does not exist`);
                    return;
                }
                active.value = (route.name === checkedTo.value.name);
            });

            return {
                exists,
                active,
                checkedTo,
                noAccess,
            };
        },
    };
</script>

<style lang="scss" scoped>
    .router-link {
        &.not-found {
            &, i {
                cursor: not-allowed;
                color: red;

                // squiggly underline
                text-decoration: underline;
                text-decoration-color: red;
                text-decoration-style: wavy;
                text-decoration-skip-ink: none;
                text-decoration-thickness: 1px;
                text-underline-position: under;
                text-underline-offset: 1px;
            }
        }
    }
</style>
