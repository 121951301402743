import { vScroll }   from "@vueuse/components";
import DOMPurify     from "dompurify";
import VueDragscroll from "vue-dragscroll";


const GlobalDirectives = {
    install(app) {
        app.directive("sane-html", (el, binding) => {
            el.innerHTML = DOMPurify.sanitize(binding.value);
        });

        app.directive("strip-html", (el, binding) => {
            const div     = document.createElement("div");
            div.innerHTML = binding.value;
            el.innerHTML  = div.textContent || div.innerText || "";
            div.remove();
        });

        app.directive("debug", (...obj) => {
            /* eslint-disable-next-line no-console */
            console.log(...obj);
        });
        app.use(VueDragscroll);
        app.directive("scroll", vScroll);
    },
};

export default GlobalDirectives;
