<template>
    <nav>
        <div class="mobile-nav" @click="openMenu">
            <i class="bi bi-list"></i> Menu
        </div>
        <ul :class="{ 'open': isOpen }">
            <smarty-router-link
                :to="{ name: 'dashboard' }"
                icon="bi bi-house"
                listitem
                slug="dashboard"
                title="Dashboard"
            >
            </smarty-router-link>
            <smarty-router-link
                :to="{ name: 'faq' }"
                icon="bi bi-question-lg"
                listitem
                slug="faq"
                title="FAQ"
            >
            </smarty-router-link>
            <smarty-router-link
                :to="{ name: 'planner' }"
                icon="bi bi-calendar-week"
                listitem
                slug="planner"
                title="Planner"
            >
            </smarty-router-link>
            <smarty-router-link
                v-if="user?.role?.level === 'manager'"
                :to="{ name: 'bedrijf' }"
                icon="bi bi-building"
                listitem
                slug="bedrijf"
                title="Bedrijf"
            >
            </smarty-router-link>

            <li
                class="logout-link"
            >
                <a
                    v-if="authStore.getUser"
                    href="#"
                    @click="logout"
                >
                    <i class="bi bi-box-arrow-right"></i> Uitloggen
                </a>
            </li>
        </ul>
    </nav>
</template>

<script>
    import { ref }      from "vue";
    import { useRoute } from "vue-router";


    import SmartyRouterLink         from "@/components/global/SmartyRouterLink";
    import { useAuthStore }         from "@/shared/Stores/AuthStore";
    import { useLesprogrammaStore } from "@/stores/modules/lesprogrammaStore";

    export default {
        name:       "NavBar",
        components: {
            SmartyRouterLink,
        },
        setup() {
            const lesprogrammaStore = useLesprogrammaStore();
            const authStore         = useAuthStore();
            const isOpen            = ref(false);
            const route             = useRoute();
            const user              = ref(authStore.getUser);
            const isCurrentRoute    = (routePath) => {
                return route.path === routePath;
            };

            const openMenu = () => {
                isOpen.value = !isOpen.value;
            };

            function logout() {
                window.location.href = "/logout";
            }


            return {
                isOpen,
                authStore,
                isCurrentRoute,
                openMenu,
                lesprogrammaStore,
                logout,
                user,
            };
        },
    };
</script>

<style lang="scss" scoped>
    .mobile-nav {
        padding: 15px;
        display: block;
        text-decoration: none;
        font-weight: 500;
        color: white;
        background: var(--bs-info);

        i {
            color: var(--nav-color);
        }
    }

    ul {
        list-style-type: none;
        padding: 0;
        max-height: 0;
        transition: all .2s ease-out;
        overflow: hidden;
        margin: 0;

        &.open {
            max-height: 500px;
            transition: all .2s ease-in;
        }

        :deep(li) {
            text-decoration: none;
            padding: 15px;
            display: block;
            border-bottom: 1px solid #90C1DC33;

            i {
                color: var(--bs-info);
                margin-right: 10px;
            }

            a {
                text-decoration: none;
                font-weight: 500;
                color: var(--nav-color);
            }

            &.active {
                background: var(--bs-info);

                i,
                a {
                    color: white;
                }
            }

            &.logout-link {
                &, i, a {
                    color: var(--bs-danger);
                }
            }
        }
    }

    /* Large devices (desktops, 992px and up) */
    @media (width >= 992px) {
        .mobile-nav {
            display: none;
            pointer-events: none;
        }

        ul {
            max-height: none;
        }
    }
</style>
