export function forceRouteSlash(route) {
    return `/${route.url.slice(0, 1) === "/" ? route.url.slice(1) : route.url}`;
}

export function forceRemoveRouteSlash(route) {
    let startsWith = /^([^a-z0-9]+)/gmi;
    let endsWith   = /([^a-z0-9]+)$/gmi;
    return route.replace(startsWith, "").replace(endsWith, "");
}

export function getSlug(url) {
    if (!url || !url.length) {
        return "";
    }
    let routeName = url.trim().replace(/^\/|\/$/mi, "");
    if (routeName.indexOf("/") !== -1) {
        routeName = routeName.split("/").shift();
    }

    let startsWith = /^([^a-z0-9]+)/gmi;
    let endsWith   = /([^a-z0-9]+)$/gmi;
    routeName      = routeName.replace(startsWith, "").replace(endsWith, "");

    return routeName;
}
