<template>
    <navbar-left-layout>
        <template #info>
            <select-program v-if="route.path.includes('/dashboard')"></select-program>
            <profile-pic-card v-else></profile-pic-card>
        </template>

        <template #nav>
            <nav-bar></nav-bar>
        </template>

        <template #content>
            <recursive-content :items="items"></recursive-content>
        </template>
    </navbar-left-layout>
</template>

<script>
    import { ref }      from "vue";
    import { useRoute } from "vue-router";

    import ProfilePicCard             from "@/components/cards/ProfilePicCard.vue";
    import SelectProgram              from "@/components/forms/info/SelectProgram.vue";
    import NavBar                     from "@/components/global/NavBar.vue";
    import { loadEditorContentBlock } from "@/shared/contentBlockUtils.js";
    import NavbarLeftLayout           from "@/views/layout/NavbarLeftLayout.vue";
    import RecursiveContent           from "@/views/RecursiveContent.vue";

    export default {
        name:       "DynamicPage",
        components: {
            ProfilePicCard,
            NavBar,
            NavbarLeftLayout,
            RecursiveContent,
            SelectProgram,
        },

        props: {
            title: {
                type:     String,
                required: true,
            },
            items: {
                type:    Array,
                default: () => [],
            },
        },

        setup() {
            const entityData = ref(null);
            const route      = useRoute();

            return {
                loadEditorContentBlock,
                entityData,
                route,
            };
        },
    };
</script>
