import { defineStore } from "pinia";

import LesprogrammaEntity from "@/shared/Entities/LesprogrammaEntity.js";
import OnderdeelEntity    from "@/shared/Entities/OnderdeelEntity.js";
import OpdrachtEntity     from "@/shared/Entities/OpdrachtEntity.js";
import ApiService         from "@/shared/Services/ApiService.js";
import { showError }      from "@/shared/utils.js";

export const useLesprogrammaStore = defineStore("lesprogrammaStore", {
    state:   () => ({
        /** @type {LesprogrammaEntity[]} Alle lesprogramma's */
        lesprogrammas: [],
        /** @type {OnderdeelEntity[]} Alle onderdelen binnen huidige lesprogramma */
        onderdelen: [],
        /** @type {OpdrachtEntity[]} Alle opdrachten binnen huidige lesprogramma */
        opdrachten: [],
        /** @type {string} UUID van het gekozen lesprogramma */
        chosenLesprogrammaUUID: null,
        /** @type {boolean} Of het lesprogramma geladen is */
        lesprogrammaLoaded: false,
        /** @type {boolean} Of het lesprogramma wordt geladen */
        lesprogrammaLoading: false,
        /** @type {object} Huidige lesprogramma met status van alles */
        lesprogrammaStatus: false,
    }),
    getters: {
        getChosenLesprogrammaUUID: state => state.chosenLesprogrammaUUID,
        getLesprogrammas:          state => state.lesprogrammas,
        getOnderdelen:             state => state.onderdelen,
        getOpdrachten:             state => state.opdrachten,
        getLesprogrammaLoaded:     state => state.lesprogrammaLoaded,
        getLesprogrammaLoading:    state => state.lesprogrammaLoading,
        getCurrentLesprogramma:    (state) => {
            return state.lesprogrammas.find(lesprogramma => lesprogramma.uuid === state.chosenLesprogrammaUUID) || null;
        },
        getLesprogrammaStatus:     state => state.lesprogrammaStatus,
    },
    actions: {
        /**
         * @param {string} uuid UUID van het lesprogramma
         */
        setChosenLesprogrammaUUID(uuid) {
            this.chosenLesprogrammaUUID = uuid;
            let lp                      = this.getCurrentLesprogramma;
            this.lesprogrammaStatus     = null;

            if (uuid !== null) {
                this.fetchLesprogrammaStatus(uuid).then((status) => {
                    this.lesprogrammaStatus = status;
                });
            }

            this.setOnderdelen(OnderdeelEntity._resolveResponseData(lp?.onderdelen || [], true));
            this.setOpdrachten(OpdrachtEntity._resolveResponseData(lp?.opdrachten || [], true));
        },
        /**
         * @param {LesprogrammaEntity[]} lesprogrammas Lesprogramma's als entities
         */
        setLesprogrammas(lesprogrammas) {
            this.lesprogrammas = lesprogrammas;
        },
        /**
         * @param {Object[]} onderdelen Onderdelen in JSON formaat
         */
        setOnderdelen(onderdelen) {
            this.onderdelen = [
                ...this.onderdelen,
                ...OnderdeelEntity._resolveResponseData(onderdelen, true).filter(onderdeel => !this.onderdelen.find(o => o.uuid === onderdeel.uuid)),
            ];
        },
        /**
         * @param {Object[]} opdrachten Opdrachten in JSON formaat
         */
        setOpdrachten(opdrachten) {
            this.opdrachten = [
                ...this.opdrachten,
                ...OpdrachtEntity._resolveResponseData(opdrachten, true).filter(opdracht => !this.opdrachten.find(o => o.uuid === opdracht.uuid)),
            ];
        },
        /**
         * @param {boolean} loaded Of het lesprogramma geladen is
         */
        setLesprogrammaLoaded(loaded) {
            this.lesprogrammaLoaded = loaded;
        },
        /**
         * @param {boolean} loading Of het lesprogramma wordt geladen
         */
        setLesprogrammaLoading(loading) {
            this.lesprogrammaLoading = loading;
        },
        /**
         * Haal alle lesprogramma's op
         * @returns {Promise}
         */
        fetchLesprogrammas() {
            this.setLesprogrammaLoaded(false);
            this.setLesprogrammaLoading(true);
            this.setLesprogrammas([]);
            let currentChosen = this.getChosenLesprogrammaUUID ?? null;
            this.setChosenLesprogrammaUUID(null);
            return new Promise((resolve) => {
                let responseData = [];
                LesprogrammaEntity.find({}, [ "" ], undefined, undefined, undefined, { cancel: false }).then(({ data }) => {
                    if (!data || !data.length) {
                        this.setLesprogrammaLoaded(true);
                        return resolve([]);
                    }
                    this.setLesprogrammas(data);
                    responseData = data;

                    // If getChosenLesprogrammaUUID not null and found in lesprogrammas, set it
                    let lp = this.getLesprogrammas.find(lp => lp.uuid === currentChosen) || null;
                    if (lp) {
                        this.setChosenLesprogrammaUUID(lp.uuid);
                    } else {
                        this.setChosenLesprogrammaUUID(data[0].uuid);
                    }
                }).finally(() => {
                    this.setLesprogrammaLoading(false);
                    this.setLesprogrammaLoaded(true);
                    resolve(responseData);
                });
            });
        },
        findOpdracht(uuid, force = false) {
            if (!force) {
                let o = this.opdrachten?.find(opdracht => opdracht.uuid === uuid) || null;
                if (o && o._uuid && o.data) {
                    return Promise.resolve(o);
                }
            }
            return new Promise((resolve) => {
                OpdrachtEntity.get(uuid).then(({ data }) => {
                    this.setOpdrachten([ data ]);
                    resolve(data);
                });
            });
        },
        findOnderdeel(uuid) {
            let o = this.onderdelen?.find(onderdeel => onderdeel.uuid === uuid) || null;
            if (o && o._uuid && o.data) {
                return Promise.resolve(o);
            }
            return new Promise((resolve) => {
                OnderdeelEntity.get(uuid).then(({ data }) => {
                    this.setOnderdelen([ data ]);
                    resolve(data);
                });
            });
        },
        findLesprogramma(uuid) {
            return this.lesprogrammas?.find(lesprogramma => lesprogramma.uuid === uuid) || null;
        },
        fetchLesprogrammaStatus(uuid) {
            if (this.getCurrentLesprogramma?.uuid === uuid && this.lesprogrammaStatus) {
                return Promise.resolve(this.lesprogrammaStatus);
            }
            return new Promise((resolve) => {
                ApiService.get("/api/v1/lesprogrammas/status", { "lesprogramma_uuid": uuid }).then(({ data }) => {
                    resolve(data);
                }).catch(e => showError(e));
            });
        },
    },
    persist: {
        enabled:    true,
        strategies: [
            {
                storage: localStorage,
                paths:   [
                    "chosenLesprogrammaUUID",
                    "lesprogrammaStatus",
                ],
            },
        ],
        serializer: {
            serialize(data) {
                return JSON.stringify({
                    chosenLesprogrammaUUID: data.chosenLesprogrammaUUID,
                    lesprogrammaStatus:     data.lesprogrammaStatus,
                });
            },
            deserialize(data) {
                let d = JSON.parse(data);
                return {
                    chosenLesprogrammaUUID: d.chosenLesprogrammaUUID,
                    lesprogrammaStatus:     d.lesprogrammaStatus,
                };
            },
        },
    },
});
