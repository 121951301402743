import { clone } from "lodash";
import mitt      from "mitt";
import moment    from "moment";
import Swal      from "sweetalert2";


export function removeDuplicates(arr) {
    return arr.filter((item, index) => arr.indexOf(item) == index);
}

export function removeDuplicatesByKey(array, key) {
    return array.filter((item, index, self) => {
        return index === self.findIndex(element => element[key] === item[key]);
    });
}

export function isEven(num) {
    return (num % 2) === 0;
}

export function isOdd(num) {
    return (num % 2) === 1;
}

export function determineBlockLabel(kind) {
    let label = "";

    if (kind === "blokgroep") {
        label = "Blokgroep";
    }

    if (kind === "onderdeel") {
        label = "Onderdeel";
    }

    if (kind === "opdracht") {
        label = "Opdracht";
    }

    if (kind === "vraag") {
        label = "Vraag";
    }

    if (kind === "content") {
        label = "Content";
    }

    if (kind === "layout") {
        label = "Layout";
    }

    if (kind === "single_use") {
        label = "Speciaal";
    }

    return label;
}

export function determineBlockTarget(draggableGroup) {
    const all = [
        "blokgroep",
        "content",
        "layout",
        "onderdeel",
        "opdracht",
        "root",
        "single_use",
        "vraag",
    ];

    if (draggableGroup === "lesprogrammas") {
        return [
            "onderdeel",
            "opdracht",
            "blokgroep",
            "content",
            "layout",
            "root",
        ];
    }

    if (draggableGroup === "onderdelen") {
        return [
            "blokgroep",
            "content",
            "layout",
            "onderdeel",
            "opdracht",
            "root",
        ];
    }

    if (draggableGroup === "opdrachten") {
        return [
            "blokgroep",
            "content",
            "layout",
            "vraag",
            "root",
        ];
    }

    if (draggableGroup === "paginas") {
        return [
            "blokgroep",
            "content",
            "layout",
            "onderdeel",
            "opdracht",
            "vraag",
            "root",
        ];
    }
    if (draggableGroup === "blokgroep") {
        return [
            "blokgroep",
            "content",
            "layout",
            "onderdeel",
            "opdracht",
            "vraag",
            "root",
        ];
    }

    return all;
}

/**
 *
 * @param {Error|{response: object}} error
 */
export function getErrorMessage(error) {
    // Check if error has response with status 204
    if (error.response && error.response.status === 204) {
        return null;
    }
    if (error.response && error.response.data && error.response.data.message) {
        return error.response.data.message;
    }
    if (error.response && error.response.data && error.response.data.error) {
        return error.response.data.error;
    }
    if (error.message) {
        return error.message;
    }
    return null;
}

export function showError(text = null, title = "", icon = "error", options = {}) {
    // If text is object with key 'message', use that as text
    if (typeof text !== "string") {
        let message = getErrorMessage(text);
        if (message) {
            text = message;
        } else {
            // Drop error
            return;
        }
    }
    Swal.fire(Object.assign({
        toast:             true,
        position:          "top-end",
        showConfirmButton: false,
        timer:             5000,
        icon:              icon,
        title:             title,
        text:              text,
        timerProgressBar:  true,
    }, options));
    let stack = clone(new Error().stack);
    /* eslint-disable-next-line no-console */
    console.error(title, text, stack);
    mtm(
        "setDocumentTitle",
        "Error/URL = " + encodeURIComponent(document.location.pathname + document.location.search) + " /From = " + encodeURIComponent(document.referrer) + "/Title =" + encodeURIComponent(title) + "/Message =" + encodeURIComponent(text)
    );
    mtm("trackPageView");
    mtm("trackEvent", "Error", "Show", title, text);
}

export function showMessage(text = null, title = "", icon = "info", options = {}) {
    if (text && typeof text === "object" && text.message) {
        text = text.message;
    }
    Swal.fire(Object.assign({
        toast:             true,
        position:          "top-end",
        showConfirmButton: false,
        timer:             5000,
        icon:              icon,
        title:             title,
        text:              text,
        timerProgressBar:  true,
    }, options));
}

export function mergeProp(target, objOrArray) {
    if (Array.isArray(target)) {
        if (Array.isArray(objOrArray)) {
            for (let obj of objOrArray) {
                if (target.indexOf(obj) === -1) {
                    target.push(obj);
                }
            }
            return target;
        } else {
            throw new Error("Cannot merge object into array");
        }
    }
    if (typeof target === "object") {
        if (typeof objOrArray === "object") {
            return Object.assign(target, objOrArray);
        } else {
            throw new Error("Cannot merge array into object");
        }
    }
}

export function mergeProps(target, ...objsOrArrays) {
    for (let objOrArray of objsOrArrays) {
        target = mergeProp(target, objOrArray);
    }
    return target;
}

/**
 * @param dateString i.e. 31-12-2021
 */
export function nlDateToDateObject(dateString) {
    let parts = dateString.split("-");
    let day   = parseInt(parts[0], 10);
    let month = parseInt(parts[1], 10) - 1;
    let year  = parseInt(parts[2], 10);
    return new Date(year, month, day);
}

export function formatDate(dateString) {
    if (!dateString) {
        return "";
    }

    const [
        year,
        month,
        day,
    ] = dateString.split("-");
    return `${day}-${month}-${year}`;
}

export function timeDiff(date1, date2) {
    if (typeof date1 === "string") {
        date1 = new Date(date1);
    }
    if (typeof date2 === "string") {
        date2 = new Date(date2);
    }
    let diff = Math.floor((date2.getTime() - date1.getTime()) / 1000);

    const yearSeconds   = 60 * 60 * 24 * 365;
    const monthSeconds  = yearSeconds / 12;
    const weekSeconds   = monthSeconds / 4;
    const daySeconds    = weekSeconds / 7;
    const hourSeconds   = daySeconds / 24;
    const minuteSeconds = 60;

    let years   = Math.floor(diff / yearSeconds);
    diff -= years * yearSeconds;
    let months  = Math.floor(diff / monthSeconds);
    diff -= months * monthSeconds;
    let weeks   = Math.floor(diff / weekSeconds);
    diff -= weeks * weekSeconds;
    let days    = Math.floor(diff / daySeconds);
    diff -= days * daySeconds;
    let hours   = Math.floor(diff / hourSeconds);
    diff -= hours * hourSeconds;
    let minutes = Math.floor(diff / minuteSeconds);
    diff -= minutes * minuteSeconds;
    diff        = Math.floor(diff);

    let result = [];

    if (years > 0) {
        result.push(`${years} ${years === 1 ? "jaar" : "jaar"}`);
    }
    if (months > 0) {
        result.push(`${months} ${months === 1 ? "maand" : "maanden"}`);
    }
    if (weeks > 0) {
        result.push(`${weeks} ${weeks === 1 ? "week" : "weken"}`);
    }
    if (days > 0) {
        result.push(`${days} ${days === 1 ? "dag" : "dagen"}`);
    }
    if (hours > 0) {
        result.push(`${hours} ${hours === 1 ? "uur" : "uren"}`);
    }
    if (minutes > 0) {
        result.push(`${minutes} ${minutes === 1 ? "minuut" : "minuten"}`);
    }
    if (diff > 0) {
        result.push(`${diff} ${diff === 1 ? "seconde" : "seconden"}`);
    }

    const max = 4;
    if (result.length > max) {
        return result.slice(0, max - 1).join(", ") + " en " + result.slice(max - 1, max);
    } else {
        return result.slice(0, -1).join(", ") + " en " + result.slice(-1);
    }
}

// Matches dd-mm-yyyy
export function isValidDate(dateString) {
    const dd_mm_yyyy_regex = /^\d{2}-\d{2}-\d{4}$/;
    return dd_mm_yyyy_regex.test(dateString);
}

export function askConfirm(title, text = "") {
    let options = {
        title:             title,
        icon:              "warning",
        showCancelButton:  true,
        confirmButtonText: "Ja",
        cancelButtonText:  "Nee",
    };
    if (text) {
        options.text = text;
    }
    return new Promise((resolve, reject) => {
        Swal.fire(options).then((result) => {
            if (result.isConfirmed) {
                resolve();
            } else {
                reject();
            }
        });
    });
}

export function mtm(...args) {
    if (!window._paq) {
        window._paq = [];
    }
    try {
        window._paq.push(args);
    } catch (e) {
        /* eslint-disable-next-line no-console */
        console.error(e);
    }
}

export function objectDiff(a, b, depth = 0) {
    let diff = null;
    if (depth > 10) {
        return { "error": "max depth reached" };
    }
    if (Array.isArray(a) && Array.isArray(b)) {
        // Treat as arrays
        diff = [];
        for (let i = 0; i < a.length; i++) {
            let d = objectDiff(a[i], b[i], depth + 1);
            if (d && d.length) {
                diff.push(d);
            }
        }
    } else if (typeof a === "object" && typeof b === "object") {
        // Treat as objects
        diff      = {};
        let aKeys = Object.keys(a || {}) || [];
        let bKeys = Object.keys(b || {}) || [];
        for (let key of aKeys) {
            if (depth > 1 && key === "_data") {
                continue;
            }
            let d = objectDiff(a[key], b[key], depth + 1);
            if (d && Object.keys(d).length) {
                diff[key] = d;
            }
        }
        for (let key of bKeys) {
            if (depth > 1 && key === "_data") {
                continue;
            }
            if (!aKeys.includes(key)) {
                diff[key] = b[key];
            }
        }
    } else {
        if (typeof a !== typeof b) {
            return {
                a: typeof a,
                b: typeof b,
            };
        } else {
            if (a !== b) {
                return {
                    a: a,
                    b: b,
                };
            }
        }
    }

    return diff;
}

/**
 * JSON parse if needed, if not needed we simply return
 * @param {object|array|string} data Data to parse
 * @param {any} [_default=[]] Default value if parsing fails
 * @param {boolean} [deep=true] If true, we also parse all keys in object or array
 * @param {number} [maxDepth=10] Maximum depth to parse
 * @param {number} [depth=0] Current depth
 * @returns {object|array} Parsed data or default value
 */
export function jsonParseIfNeeded(data, _default = [], deep = false, maxDepth = 10, depth = 0) {
    if (depth > maxDepth) {
        return "...";
    }
    if (typeof data === "string" && (
        data.trim().startsWith("{") && data.trim().endsWith("}") ||
        data.trim().startsWith("[") && data.trim().endsWith("]")
    )) {
        let dcD = null;
        try {
            dcD  = JSON.parse(data);
            data = dcD;
        } catch (e) {
            /* eslint-disable-next-line no-console */
            console.error(e);
            data = _default;
        }
    }
    if (!data) {
        data = _default;
    }
    // If deep, we check each key, if object or array we loop through, if string we parse if needed with deep = true
    if (deep) {
        if (Array.isArray(data)) {
            for (let i = 0; i < data.length; i++) {
                data[i] = jsonParseIfNeeded(data[i], _default, deep, maxDepth, depth + 1);
            }
        } else if (typeof data === "object") {
            for (let key in data) {
                data[key] = jsonParseIfNeeded(data[key], _default, deep, maxDepth, depth + 1);
            }
        }
    }
    return data;
}

/**
 * Sensitieve data filteren
 * @param {object|array} data Data om te filteren
 * @param {string[]} [sensitive=["password", "token", "secret"]] Gevoelige keys
 * @param {string|function} [replaceWith="<SECRET>"] Vervangende waarde, of functie die waarde teruggeeft
 * @param {boolean} [appendHash=true] Hash toevoegen aan vervangende waarde, geldt alleen als replaceWith een string is
 * @returns {object|array} Gefilterde data
 */
export function filterSensitive(data, sensitive = [
    "password",
    "token",
    "secret",
], replaceWith                                  = "<SECRET>", appendHash = true) {
    if (Array.isArray(data)) {
        return data.map(item => filterSensitive(item, sensitive));
    }
    if (typeof data === "object") {
        let result = {};
        for (let key in data) {
            if (sensitive.indexOf(key) > -1) {
                if (typeof replaceWith === "string") {
                    result[key] = replaceWith;
                    if (appendHash) {
                        result[key] += ` (${createHash(data[key])})`;
                    }
                } else if (typeof replaceWith === "function") {
                    result[key] = replaceWith(data[key]);
                }
            } else {
                result[key] = data[key];
            }
        }
        return result;
    }
    return data;
}

/**
 * Functie om een array van blokken te filteren op basis van een callback functie.
 * @param items Items om te filteren
 * @param callbackFn Filter functie (moet true returnen om blok te behouden, of blok data zelf om deze data te behouden)
 * @param result Resultaat array (wordt recursief gevuld, hoef je niet mee te geven)
 * @returns {*[]} Array met gefilterde blokken
 */
export function filterBlocks(items, callbackFn = (block) => {
    return block;
}, result                                      = []) {
    for (let item of items) {
        if (item.children && item.children.length > 0) {
            result = filterBlocks(item.children, callbackFn, result);
        }
        let filtered = callbackFn(item);
        if (filtered === true) {
            result.push(item);
        } else if (filtered) {
            result.push(filtered);
        }
    }
    return result;
}

export function createHash(_str) {
    let hash = 0,
        i,
        chr;
    if (_str.length === 0) {
        return hash;
    }
    for (i = 0; i < _str.length; i++) {
        chr  = _str.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
}

/**
 * Capitalize first letter of every word in string
 * @param str {string} String to capitalize
 * @param all {boolean} If true, capitalize first letter of every word, if false, only first letter of first word
 */
export function ucfirst(str, all = true) {
    let newWord = true;
    let result  = "";
    for (let i = 0; i < str.length; i++) {
        let char = str.charAt(i);
        if (newWord) {
            if (all || i < 1) {
                char = char.toUpperCase();
            }
            newWord = false;
        }
        if (char.match(/\W/)) {
            newWord = true;
        }
        result += char;
    }
    return result;
}

/**
 * Verander date object naar datum tekst (bijv. 'Vrijdag 21 juni, 2024')
 * @param date {Date|string} Datum object
 */
export function datumTextFromDate(date) {
    return ucfirst(moment(date).locale("nl").format("dddd DD MMMM YYYY"), false);
}

export function setLocale(m) {
    if (!m.locales().includes("nl")) {
        m.defineLocale("nl", {
            months:                 "januari_februari_maart_april_mei_juni_juli_augustus_september_oktober_november_december".split("_"),
            monthsShort:            "jan_feb_mrt_apr_mei_jun_jul_aug_sep_okt_nov_dec".split("_"),
            weekdays:               "zondag_maandag_dinsdag_woensdag_donderdag_vrijdag_zaterdag".split("_"),
            weekdaysShort:          "zo_ma_di_wo_do_vr_za".split("_"),
            weekdaysMin:            "zo_ma_di_wo_do_vr_za".split("_"),
            longDateFormat:         {
                LT:   "HH:mm",
                LTS:  "HH:mm:ss",
                L:    "DD-MM-YYYY",
                LL:   "D MMMM YYYY",
                LLL:  "D MMMM YYYY HH:mm",
                LLLL: "dddd D MMMM YYYY HH:mm",
            },
            calendar:               {
                sameDay:  "[vandaag om] LT",
                nextDay:  "[morgen om] LT",
                nextWeek: "dddd [om] LT",
                lastDay:  "[gisteren om] LT",
                lastWeek: "[afgelopen] dddd [om] LT",
                sameElse: "L",
            },
            relativeTime:           {
                future: "over %s",
                past:   "%s geleden",
                s:      "een paar seconden",
                ss:     "%d seconden",
                m:      "één minuut",
                mm:     "%d minuten",
                h:      "één uur",
                hh:     "%d uur",
                d:      "één dag",
                dd:     "%d dagen",
                w:      "één week",
                ww:     "%d weken",
                M:      "één maand",
                MM:     "%d maanden",
                y:      "één jaar",
                yy:     "%d jaar",
            },
            dayOfMonthOrdinalParse: /\d{1,2}(ste|de)/,
            ordinal:                function (number) {
                return number + ((number === 1 || number === 8 || number >= 20) ? "ste" : "de");
            },
            week:                   {
                dow: 1, // Monday is the first day of the week.
                doy: 4,  // The week that contains Jan 4th is the first week of the year.
            },
        });
    }
}

export function joinIfNeeded(strOrArray) {
    if (Array.isArray(strOrArray)) {
        return strOrArray.join(", ");
    }
    if (typeof strOrArray === "object") {
        return JSON.stringify(strOrArray);
    }
    return strOrArray;
}


export function getBreadcrumbFromHash(hash) {
    if (!hash || !hash.length || hash === "#") {
        return null;
    }
    let matches = hash.match(/BC_(.+)$/);
    if (matches) {
        return getBreadcrumbData(matches[1]);
    }
}

const getBreadcrumbData = (hash) => {
    return JSON.parse(atob(hash));
};

export function getNewBreadcrumbData(hash, currentRoute, target) {
    let current  = getBreadcrumbFromHash(hash);
    let curRoute = {
        name:   currentRoute.name,
        params: currentRoute.params,
        title:  currentRoute?.meta?.title ?? ucfirst(currentRoute.name),
    };
    if (!current || !current.length) {
        // No existing breadcrumbs yet
        if (currentRoute.name !== "dashboard") {
            // Not on dashboard
            current = [
                {
                    name:   "dashboard",
                    params: {},
                    title:  ucfirst("dashboard"),
                },
                curRoute,
            ];
        } else {
            // On dashboard
            current = [ curRoute ];
        }
    } else {
        // We have current, so we should add curRoute to it
        current = [
            ...current,
            curRoute,
        ];
    }

    // We have routes, check if target is in this array
    let index = current.findIndex((route) => {
        return route.name === target.name && JSON.stringify(route.params) === JSON.stringify(target.params);
    });
    // If target found, we're going back in history, so delete index and everything after
    if (index > -1) {
        current = current.slice(0, index);
    }

    // If there is a loop, remove everything after first occurrence
    let loopIndex = current.findIndex((route, i) => {
        return current.findIndex((r, j) => {
            return i !== j && r.name === route.name && r.params === route.params;
        }) > -1;
    });
    if (loopIndex > -1) {
        current = current.slice(0, loopIndex);
    }

    return current;
}

/**
 * Keys van object ophalen
 * @param {object} object Object waarvan je keys wil ophalen
 * @param {string[]} keys Array met keys die je wil ophalen
 * @returns {{}} Object met alleen de keys die je wilde ophalen
 */
export function objectKeys(object, keys) {
    let result = {};
    for (let key of keys) {
        result[key] = object[key];
    }
    return result;
}

// Create a mitt instance
const emitter = mitt();

// Export the mitt instance
export default emitter;
