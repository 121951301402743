<template>
    <div class="spinner-border text-info" role="status"></div>
</template>

<script>
    export default {
        name: "ComponentLoadingComponent",
        setup() {
            return {};
        },
    };
</script>
