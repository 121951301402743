<template>
    <div class="layout-default">
        <app-header></app-header>
        <div class="container custom-container">
            <div class="row">
                <div class="col-lg-3">
                    <div class="sidebar d-flex flex-column justify-content-between sidebar-nav">
                        <slot name="info">
                            <profile-pic-card></profile-pic-card>
                        </slot>
                        <slot name="nav"></slot>
                    </div>
                </div>
                <div class="col-lg-9">
                    <slot name="content"></slot>
                </div>
            </div>
        </div>
        <app-footer></app-footer>
    </div>
</template>

<script>
    import bootstrap     from "bootstrap/dist/js/bootstrap.bundle.min.js";
    import { onMounted } from "vue";

    import ProfilePicCard from "@/components/cards/ProfilePicCard.vue";
    import AppFooter      from "@/components/global/AppFooter.vue";
    import AppHeader      from "@/components/global/AppHeader.vue";

    export default {
        name:       "NavbarLeftLayout",
        components: {
            ProfilePicCard,
            AppFooter,
            AppHeader,
        },
        setup() {
            const initBootstrap = () => {
                const tooltipTriggerList = document.querySelectorAll("[data-bs-toggle=\"tooltip\"]");
                [ ...tooltipTriggerList ].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl));

                const popover = document.querySelectorAll("[data-bs-toggle=\"popover\"]");
                [ ...popover ].map(popoverTriggerEl => new bootstrap.Popover(popoverTriggerEl));
            };

            onMounted(initBootstrap);

            return {
                initBootstrap,
            };
        },
    };
</script>

<style lang="scss" scoped>
    @media (width >= 992px) {
        .sidebar-nav {
            height: 100%;
            max-height: calc(100vh - 150px);
            padding-bottom: 50px;
        }
    }
</style>



